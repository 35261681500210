import { buildConfig } from 'payload/config';
import path from 'path';
import Users from './collections/Users';
import Events from './collections/Events';
import Media from './collections/Media';

export default buildConfig({
  serverURL: process.env.NODE_SERVER_URL, //'http://localhost:3000',
  admin: {
    user: Users.slug,
  },
  collections: [
    Users,
    Events,
    Media,
  ],
  typescript: {
    outputFile: path.resolve(__dirname, 'payload-types.ts'),
  },
  graphQL: {
    schemaOutputFile: path.resolve(__dirname, 'generated-schema.graphql'),
  },
  cors: ['*'],
})
