import { CollectionConfig } from 'payload/types';

const Events: CollectionConfig = {
  slug: 'events',
  admin: {
    useAsTitle: 'eventTitle',
  },
  access: {
    read: ({ req: { user }}) => {
      console.log(user);

      if (user) return true;
      return {
        eventStatus: {
          equals: 'published',
        }
      }
    },
    create: ({ req: { user }}) => {
      if (user) return true;
    },
    update: ({ req: { user }}) => {
      if (user) return true;
    },
    delete: ({ req: { user }}) => {
      if (user) return true;
    },
  },
  fields: [
    {
      name: 'eventStatus',
      type: 'select',
      defaultValue: 'new',
      options: [
        {
          label: 'New',
          value: 'new',
        },
        {
          label: 'Published',
          value: 'published',
        },
        {
          label: 'Archived',
          value: 'archived',
        }
      ]
    },
    {
      name: 'eventTitle',
      type: 'text',
    },
    {
      name: 'eventDate',
      type: 'date',
      index: true,
      admin: {
        date: {
          pickerAppearance: 'dayAndTime',
        },
      }
    },
    {
      name: 'eventSummary',
      type: 'textarea',
    },
    {
      name: 'eventDetails',
      type: 'richText',
    },
    {
      name: 'eventMainImage',
      type: 'upload',
      relationTo: 'media',
    },
    {
      name: 'eventImages',
      type: 'array',
      fields: [
        {
          name: "title",
          type: "text",
        },
        {
          name: "image",
          type: "upload",
          relationTo: "media",
          required: true,
        },
        {
          name: "caption",
          type: "text",
        },
      ],
    },
  ],

}

export default Events;